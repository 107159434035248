/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// swiper.js
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import 'swiper/scss/navigation';


// Solucionar error de campos "autofill" en Chrome:
input:-webkit-autofill {
  height: 0px;
  -webkit-box-shadow:0 0 0 10px white inset;
  -webkit-text-fill-color: black;
  font-weight: 500px;
}
input:-webkit-autofill:focus {
  height: 0px;
  -webkit-box-shadow: 0 0 0 10px white inset;
  -webkit-text-fill-color: black;
  font-weight: 500px;
}

/* App custom styles */
ion-skeleton-text {
  height: 1.15em;
}

ion-list.autocomplete {
  position: absolute;
  width: inherit;
  overflow-y: auto;
  z-index: 99999;
  max-height: 50vh;
  ion-item:hover {
    cursor: pointer;
  }
}

.addFav {
  transform: translate(10px, -17px) scale(0.75);
}
.deleteIcon {
  transform: translate(2px, 12px) scale(1.5);
  color: var(--ion-color-danger);
  cursor: pointer;
}

.masMenosFav {
  transform: translate(15px, 15px) scale(0.75);
}
.masMenosCol {
  padding: 0 1.6em 0 0;
  margin: 0;
}
.masMenosColGrid, .masMenosColGridRow {
  padding: 0;
  margin: 0;
}

.small {
  font-size: 0.8em;
}

ion-label {
  opacity: 1 !important;
}
.native-input[disabled].sc-ion-input-md {
  opacity: 1;
}
.native-textarea[disabled].sc-ion-textarea-md {
  opacity: 1;
}
.checkbox-disabled {
  opacity: 1 !important;
}
.select-disabled {
  opacity: 1 !important;
}

// ion-input[readonly] {
//   color: var(--ion-color-medium-shade);
// }

ion-input[disabled] {
  color: var(--ion-color-dark);
}

// Interface más ancha para los ion-select popover:
.wider-popover
{
  --width: 95%;
  --max-width: 35em;
}

ion-progress-bar {
  transform: translateY(-7px) scaleY(1.5);
  z-index: 99999;
}

.file-input-container {

  input[type="file"] {
      display: none;
  }

  label {
      border: 1px solid #ccc;
      padding: 6px 12px;
      cursor: pointer;
  }

  ion-list {
    margin-top: 0.6em;

    ion-icon {
      cursor: pointer;
    }
  }

}

ion-title {
  color: var(--ion-color-primary);
}

ion-item.ion-invalid {
  --border-color: var(--ion-color-danger);
  color: var(--ion-color-danger);
  ion-label {
    font-weight: bolder;
  }
  ion-label::after { content: " (*)" }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
   -webkit-box-shadow: 0 0 0 30px var(--ion-item-background) inset !important;
   box-shadow: 0 0 0 30px var(--ion-item-background) inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
 -webkit-text-fill-color: var(--ion-text-color) !important;
}

// ion-icon {
//   pointer-events: none;
// }

// Evita que los campos "select" queden con su parte inferior a los demás dentro de un ion-grid
ion-row {
  align-items: flex-end;
}

ion-card-title {
  font-size: 1.1em;
}
ion-footer {
  background-color: var(--ion-item-background) !important;
}


img.logo {
  max-width: 400px;
  width: 75vw;
  max-height: 200px;
  margin: 2.5em auto 1em;
  display: block;
}

.logos {
  margin: 2em 0;
  text-align: center;

  a {
    margin: 0 0.5em;
  }
}

div.captcha {
  width: 304px;
  margin: 1em auto 0 auto;
}

a.padding {
  padding: .5em 0;
  display: inline-block;
}
